<template>
  <div>
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMMTCHomeSection />
    </template>
    <template v-else>
      <EvoMTCHomeSection />
    </template>
  </div>
</template>
<script>
import EvoMTCHomeSection from '@/components/mtc_sections/Home.vue';
import EvoMMTCHomeSection from '@/mobile/components/mtc_sections/MHome.vue';
export default {
  name: 'EvoMTCHomeView',
  components: {
    EvoMTCHomeSection,
    EvoMMTCHomeSection
  }
};
</script>
