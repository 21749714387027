<template>
  <div class="evo-mtc-home-wrapper evo-mobile mx-auto">
    <v-row class="evo-policy-items">
      <v-col cols="12" v-for="(item, index) in items" :key="index">
        <router-link :to="item.link" target="_self" class="router-link">
          <div class="evo-item-wrapper">
            <div class="evo-item">
              <div class="evo-item-title evo-text-64 text-center mx-auto">{{ $t(item.title) }}</div>
            </div>
            <div class="evo-policy-info">
              <div class="evo-item-date evo-text-22 evo-opensans-regular">
                {{ item.date }}
              </div>
              <div class="evo-item-desc evo-text-33">{{ $t(item.desc) }}</div>
              <div class="evo-read-more text-right">
                <span class="evo-link evo-text-22 evo-opensans-regular">
                  {{ $t(item.linkText) }}
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mtcHomeMixin } from '@/mixins/mtc.js';
export default {
  name: 'EvoMMTCHomeSection',
  mixins: [mtcHomeMixin]
};
</script>
<style lang="scss" scoped>
.evo-mtc-home-wrapper {
  position: relative;
  padding-top: 40px;

  .evo-policy-items {
    margin-bottom: 110px;

    .evo-item-wrapper {
      background: #ffffff;
      box-shadow: 38px 38px 85px 0px rgba(0, 0, 0, 0.1);
      border-radius: 9px;
      min-height: 976px;
    }

    .evo-item {
      position: relative;
      background-image: url('~@/assets/mtc/mtc-bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 24px;
      height: 658px;

      .evo-item-title {
        margin-top: 230px;
        max-width: 600px;
        color: #ffffff;
      }
    }

    .evo-policy-info {
      padding: 0 60px;

      .evo-item-date {
        margin-bottom: 16px;
        color: #595f6f;
      }

      .evo-item-desc {
        color: #1a47b0;
      }

      .evo-read-more {
        margin-top: 16px;
        .evo-link {
          color: #595f6f;
        }
      }
    }
  }
}
</style>
